import React from "react"
import Layout from "../../../templates/layout"
import SEO from "../../../components/seo/seo"
import Hero from "../../../components/hero/hero"
import Button from "../../../components/button/button"
import HaveQuestions from "../../../components/haveQuestions/haveQuestions"
import ExpandingCard from "../../../components/ExpandingCard/ExpandingCard"
import CopayCard from "../../../images/CareASSIST_copay card_v3_20220825-x.svg"
import Wallet from "../../../images/icons/icon-wallet-sarclisa.svg"
import Umbrella from "../../../images/icons/icon-umbrella-sarclisa.svg"
import USA from "../../../images/icons/icon-usa-sarclisa.svg"
import BrandLogo from "../../../images/logos/sarclisa-logo-new.svg";
import QuestionsIcon from "../../../images/icons/icon-questions-sarclisa.svg"
import HeroIcon from "../../../images/icons/icon-financial-sarclisa-hero.svg"
import BackToTop from "../../../components/backToTop/BackToTop"
import PrescribingInfo from "../../../components/prescribingInfo/prescribingInfo"
import Card from "../../../components/card/card"

const pageContent = () => (
  <Layout pageid="page-assistance">
    <SEO
      title="CareASSIST for SARCLISA® (isatuximab-irfc) financial assistance for eligible patients | HCP Site"
      keywords="CareASSIST, Sanofi, SARCLISA® (isatuximab-irfc), HCP Site, Financial Assistance"
      description="Info for providers about CareASSIST Financial Assistance. Find out how much your eligible patients may save on costs for SARCLISA. See full Prescribing Info"
    />

    <Hero
      brandLogo={BrandLogo}
      headline="Financial assistance"
      copy="CareASSIST offers programs and support to help your patients with paying for their medication. Our Care Managers can answer questions and connect patients to additional financial resources as needed"
      heroImgClass="hero-icon"
      hasButton={false}
      hasLogo={true}
      hasIcon={true}
      heroIcon={HeroIcon}
      iconAlt="Financial Assistance Icon"
      brandAlt="Sarclisa logo"
    />

    <section className="content-section">
      <main>
        <div className="main-content">
          <ExpandingCard cardClass="sarclisa"
            mainContent={() => (
              <div className="expanding-card-content">
                <h2>CareASSIST Copay Program</h2>
                <p className="mt-1">
                  Eligible, commercially insured patients may be able to receive copay
                  support through the <b>Copay Program</b>
                </p>
              </div>
            )}
            hiddenContent={() => (
              <div className="expanding-card-content hidden mt-1">
                <p className="">Your patients may pay as little as $0 for their prescribed medication, 
                  including any product-specific copay and coinsurance – up to $25,000 in
                  assistance per calendar year. Restrictions apply.
                </p>

                <figure className="copay-card m-b-20 mobile-m-b-0">
                  <div className="mobile-m-b-15">
                    <img src={CopayCard} alt="Copay card" className="mobile-m-b-0" />
                    <p className="m-b-0 fs-12 lh100">This card is an example </p>
                  </div>
                  <figcaption>
                    <p className="word-break lh120 m-b-10">
                      Your patient will present their copay card along with their
                      insurance card to you. If your patient has claims they
                      would like to submit for reimbursement consideration,
                      instruct them to click{" "}
                      <a
                        href="https://portal.trialcard.com/sanofi/careassist"
                        target="_blank"
                        className="underline black"
                        rel="noreferrer"
                        id="hcp-sarclisa-financial-assistance-copaycard-here"
                      >
                        here
                      </a>.{" "}
                      Follow the prompts to Upload Documents and upload supporting documentation
                      including a Pharmacy receipt and primary Explanation of Benefits (EOBs).
                    </p>
                    
                  <h3 className="text-with-arrow m-t-15"><a href="https://portal.trialcard.com/sanofi/careassist/" id="hcp-sarclisa-financial-assistance-copaycard" target="_blank" className="no-underline lh120 blue">See if your patients qualify</a></h3>
                  </figcaption>
                </figure>
                <p className="lh120">
                    IMPORTANT NOTICE: Maximum benefit of $25,000 per calendar year. Prescription must be for an approved indication. Not valid for prescriptions covered by or submitted for reimbursement, in whole or in part, under Medicare, Medicaid, VA, DoD, TRICARE, or similar federal or state programs including any state pharmaceutical assistance programs. Not valid where prohibited by law. This offer is nontransferable, limited to one per person, and cannot be combined with any other offer or discount. Any savings provided by the program may vary depending on patients' out-of-pocket costs. Sanofi reserves the right to modify or discontinue the programs at any time without notice. All program details provided upon registration.
                </p>


                <hr className="m-b-20"/>
                <blockquote>
                  <h3>Out-of-pocket responsibility</h3>
                  <p>
                    Patients are responsible for any out-of-pocket expenses 
                    associated with prescribed medication that exceed the program 
                    assistance limit of $25,000 per year. This is in addition to 
                    non–product-specific expenses related to supplies and procedures 
                    for physician-related services.
                  </p>
                  <h3>Eligibility requirements</h3>
                  <ul>
                    <li>
                      <b>Insurance</b> - Patients must have commercial or
                      private insurance, including state or federal employee
                      plans and health insurance exchanges
                    </li>
                    <li>
                      <b>Residency</b> - Patients must be residents of the
                      United States or its territories or possessions
                    </li>
                    <li>
                      <b>Prescription</b> - Patients must be prescribed on-label 
                    </li>
                  </ul>
                  <p>
                    <b>
                      There is no income requirement for patients in this
                      program. Other conditions apply.
                    </b>
                  </p>
                 
                </blockquote>
                

                <h3 className="mt-1">
                  How the CareASSIST Copay Program handles reimbursement
                </h3>
                <p className="bold">Pharmacy claim instructions: </p>

                <p>Use patient’s prescription insurance for the primary claim. Process a COB claim to PDMI under BIN 610020 as the
                  secondary claim. If you have any questions or issues with submitting a claim, please call 1-844-322-9286.</p>
                <p className="bold">Medical claim instructions: </p>
                <p>Submit the primary claim to the patient's medical benefit insurance per your normal process. Secondary claims for copay assistance may be submitted using <span className="underline">one of the following methods</span>:</p>
                <p className="bold">	Electronic claims submittal (via your medical billing software)</p>
                <p>Submit an ANSI ASC X12N electronic claim using Payer ID #56155, Group #00003674 (for CareASSIST) as the
                  secondary payer. Please include the patient’s Copay Program Member ID.</p>
                <p className="bold">Upload claims documentation <a href="https://portal.trialcard.com/sanofi/careassist" id="hcp-sarclisa-financial-upload-claims" target="_blank">here</a></p>
                <p>Follow the prompts to upload the required documentation.</p>
                <p className="bold">Paper claims submittal</p>
                <p>Paper claims may be faxed to 855-915-3050 or mailed to:</p>
                <p>TrialCard, Inc.<br/>
                  2250 Perimeter Park Drive, Suite 	300<br/>
                  Morrisville, NC 27560</p>
                <p>Please submit a CMS 1500 or UB-04 Claim Form along with a copy of the primary insurance EOB showing the patient’s out-of-pocket costs for SARCLISA. Please ensure the claim form includes the CPT/J-Code,
                  NDC and/or Drug Name.</p>
                <p className="bold">Reimbursement options: </p>
                <p>InstaMed is the payment vendor for claims paid under this program. A site’s payment options are a paper check
                  mailed to site (using the address included on the CMS 1500 or UB-04), or electronic ACH/EFT payments to a site’s
                  designated bank account.</p>
                <p>A site will automatically be reimbursed by check if it does not already have an existing InstaMed account. To receive
                  electronic ACH/EFT payments, the site must set up an account online by visiting <a href="https://www.instamed.com/" id="hcp-sarclisa-financial-instamed-link" target="_blank">https://www.instamed.com</a> and
                  selecting Join. For additional support, you may contact InstaMed at <a href="mailto:support@instamed.com">support@instamed.com</a> or 1-866-467-8263.
                </p>
                <p>Sites with an existing InstaMed account for other payers for electronic payments will be reimbursed in the same
                  manner (there is no need to create a new account). Sites wishing to receive checks for Copay Program payments are
                  not required to create an account with InstaMed.</p>
              </div>
            )}
          />
          <ExpandingCard cardClass="sarclisa"
            mainContent={() => (
              <div className="expanding-card-content">
                <h2>CareASSIST Patient Assistance Program</h2>
                <p className="mt-1">
                  Patients who are uninsured or underinsured and meet program eligibility requirements can receive
                  their medication at no cost from Sanofi Cares North America through the{" "}
                  <b>CareASSIST Patient Assistance Program</b>
                </p>
              </div>
            )}
            hiddenContent={() => (
              <div className="expanding-card-content hidden">
                <blockquote>
                  <h3>Eligibility requirements</h3>
                  <figure className="uninsured flex-section">
                    <img src={Umbrella} alt="umbrella icon" />
                    <figcaption>
                    Patients must be uninsured or lack coverage for their
                      prescribed medication*
                    </figcaption>
                  </figure>
                  <figure className="resident flex-section">
                    <img src={USA} alt="united states icon" />
                    <figcaption>
                      Patients must be residents of the United States or its
                      territories or possessions and under the care of a licensed 
                      healthcare provider with a practice in the US
                    </figcaption>
                  </figure>
                  <figure className="income flex-section">
                    <img src={Wallet} alt="wallet icon" />
                    <figcaption>
                      Patients must have an annual household income that does not
                      exceed the greater of $100,000 or 500% of the Federal Poverty Level
                    </figcaption>
                  </figure>
                  <p className="sosumi">
                    *Patients with Medicare Part B coverage with no
                    supplemental insurance coverage may be eligible.
                  </p>
                </blockquote>
                <p className="m-t-20">A member of the CareASSIST team will: </p>
                <ul>
                  <li>Follow up on any missing information from submitted enrollment materials </li>
                  <li>
                    Notify HCP and patient of patient enrollment status and provide approval or denial explanation
                  </li>
                  <li>
                    Coordinate shipments of medications to your office and other
                    sites of care per information provided on product request form
                  </li>
                  <li>Follow up to ensure continued enrollment and investigate additional support needs </li>
                </ul>
                <h3 className="text-with-arrow"><a href="/hcp/sarclisa/enrollment" id="hcp-sarclisa-financial-assistance-learn-more" className="no-underline lh120 blue">Enroll your patient in CareASSIST to learn more</a></h3>
              </div>
            )}
          />



          <div className="title-bar">
              <h2>Useful resources</h2>
              {/* <Link to="/hcp/elitek/resource-support/" className="view-all" title="View alll resources link">View all resources</Link> */}
          </div>
          <div className="flex-section center">
              <Card
              cardClass="useful-resources"
                  cardTitle="Copay program flashcard"
                  cardText=""
                  isExternalLink={true}
                  linkUrl="../../../pdfs/careassist-copay-program-hcp-flashcard.pdf"
                  linkClass="download"
                  linkTitle="Download the Copay Program flashcard"
                  linkText=""
                  linkId="hcp-sarclisa-financial-assistance-copay-program-flashcard"
              />
              <Card
              cardClass="useful-resources"
                  cardTitle="Patient Assistance Program flashcard"
                  cardText=""
                  isExternalLink={true}
                  linkUrl="../../../pdfs/careassist-pap-flashcard.pdf"
                  linkClass="download"
                  linkTitle="View the Letter of medical necessity site"
                  linkText=""
                  linkId="hcp-sarclisa-financial-assistance-pap-flashcard"
              />
          </div>
        </div>
      </main>

      <PrescribingInfo linkId="hcp-sarclisa-financial-prescribing-information" link="https://products.sanofi.us/Sarclisa/sarclisa.pdf" hideBoxed="hide-boxed"/>
      <HaveQuestions icon={QuestionsIcon} />
    </section>
    <div className="back-to-top-container">
        <BackToTop />
      </div>
  </Layout>
);

export default pageContent